<template>
  <div class="bg-[#FFFFFF]">
    <headerView />
    <div>
      <div class="hidden lg:block">
        <div class="h-full rounded-b-xl xl:rounded-b-2xl px-5">
          <div class="max-w-7xl mx-auto pt-[110px]">
            <!-- breadcrums -->
            <div class="p-5">
              <p
                class="text-[#0CB1EF] md:text-black text-[14px] hidden lg:block"
              >
                <a href="/" class="text-[#EC027C]">Home</a>
                <span class="text-[#EC027C]"> &nbsp;/&nbsp;</span>
                <a class="text-[#4D4D4D]">Blogs</a>
              </p>
            </div>
            <!-- breadcrums -->
            <div
              class="max-w-7xl mx-auto lg:ml-[8%] xl:ml-[6%]"
              v-if="validatingCondition"
            >
              <div class="flex flex-col gap-3">
                <p class="text-[#EC027C] text-[14px]">
                  {{ blogsDetail.category.title }}
                </p>
                <h1
                  class="text-black text-[30px] lg:text-[40px] font-bold text-start leading-tight max-w-[70%]"
                >
                  {{ blogsDetail.title }}
                </h1>
                <!-- <p class="text-[#4D4D4D] text-[14px]">{{blogsDetail.read_time}} minutes</p> -->
              </div>
            </div>
            <div class="flex gap-x-9 pt-5">
              <!-- left sticky icon -->
              <div class=" relative left-0  top-14">
              <div class="flex  flex-col items-center gap-4 sticky top-[200px]   ">
                <p class="text-[18px] text-[#000000]">Share</p>
                <img
                  src="@/assets/blogs_detail/logo.svg"
                  alt=""
                  srcset=""
                  class="w-[27px] h-[27px] cursor-pointer"
                  @click.prevent="
                    shareOnTwitter(
                      blogsDetail.title,
                      `https://shardaonline.online/blogs/${blogsDetail.slug}`
                    )
                  "
                />
                <img
                  src="@/assets/blogs_detail/Icon awesome-linkedin.svg"
                  alt=""
                  srcset=""
                  class="w-[27px] h-[27px] cursor-pointer"
                  @click.prevent="
                    shareOnLinkedIn(
                      blogsDetail.title,
                      `https://shardaonline.online/blogs/${blogsDetail.slug}`
                    )
                  "
                />
                <img
                  src="@/assets/blogs_detail/Icon awesome-link.svg"
                  alt=""
                  srcset=""
                  class="w-[27px] h-[27px] cursor-pointer"
                  @click.prevent="
                    copyLink(
                      `https://shardaonline.online/blogs/${blogsDetail.slug}`
                    )
                  "
                />
                <div
                  class="text-[#ec027c] text-sm font-bold w-[3rem]"
                  v-if="linkedCopiedStatus"
                >
                  Linked copied
                </div>
              </div>
              </div>
              <!-- left sticky icon -->

              <!-- desktop banner -->
              <div class=" w-[750px]">
                <div class="">
                  <div>
                    <div class="flex flex-col gap-2">
                      <div class="w-full h-[350px]">
                        <img
                          :src="blogsDetail.image"
                          alt="desImage"
                          class="rounded-md h-full w-full"
                        />
                      </div>

                      <div class="flex flex-col gap-5 pt-3">
                        <p class="text-black text-[14px] text-start font-bold">
                          {{ formatDate(blogsDetail.publish_at) }}
                        </p>
                        <!-- <h1 class="text-[25px] font-medium text-[#18479E]">
                          India, the world’s largest democracy, pulsates with a
                          vibrant political ecosystem. Understanding this
                          dynamic system requires delving into the realm of
                          Political Science – a discipline that equips you to
                          analyze power, governance, and the complex interplay
                          of forces that shape India’s political landscape. This
                          blog post explores the vast scope of learning
                          Political Science in the Indian context. We’ll delve
                          into the key areas of study, career opportunities, and
                          valuable skills you’ll acquire.
                        </h1> -->
                      </div>

                      <div class="mt-3">
                        <p
                          class="text-left text-[14px] md:text-[16px] pb-4 rounded-lg"
                          v-html="blogsDetail.content"
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--trending-->
              <div class="w-[320px]">
                <div>
                  <h2 class="text-[24px] lg:text-[30px] text-start">
                    <span class="text-[#18479E]">Most Popular </span>
                    <span class="text-[#EC027C]">Articles</span>
                  </h2>
                </div>
                <div class="flex flex-col gap-8 mt-8">
                  <div class="" v-for="blog in popularData" :key="blog">
                    <div class="bg-white shadow-sm rounded-2xl border">
                      <div
                        class="p-4 text-start h-[170px] flex flex-col justify-between"
                      >
                        <h2 class="text-black text-[18px] line-clamp-3">
                          {{ blog.title }}
                        </h2>
                        <p class="text-[#454545] text-[14px] text-start">
                          {{ blog.category.title }} |
                          {{ formatDate(blog.publish_at) }}
                        </p>

                        <a :href="`/blogs/${blog.slug}`">
                          <p
                            class="text-[14px] lg:text-[16px] font-medium text-[#EC027C]"
                          >
                            Read More
                          </p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--trending-->
            </div>
          </div>
        </div>
      </div>

      <!-- mobile banner-->

      <div class="lg:hidden bg-[#F6F8FB] pt-[90px]">
        <div
          class="rounded-b-xl flex flex-col gap-7 px-5"
          v-if="validatingCondition"
        >
          <div class="flex flex-col gap-3">
            <p class="text-[#EC027C] text-[14px]">
              {{ blogsDetail.category.title }}
            </p>
            <h2
              class="text-black h1 font-bold text-start leading-tight"
            >
              {{ blogsDetail.title }}
            </h2>
            <!-- <p class="text-[#4D4D4D] text-[12px]">{{blogsDetail.read_time}} minutes</p> -->
          </div>
          <div>
            <div class="flex flex-col gap-3">
              <div class="">
                <img
                  :src="blogsDetail.image"
                  alt="mobimage"
                  class="h-[280px] rounded-2xl w-full"
                />
              </div>
              <div class="flex flex-col gap-5 pt-3">
                <p class="text-black text-[14px] text-start font-bold">
                  {{ formatDate(blogsDetail.publish_at) }}
                </p>
                <!-- <h1 class="text-[20px] font-medium text-[#18479E]">
                  India, the world’s largest democracy, pulsates with a vibrant
                  political ecosystem. Understanding this dynamic system
                  requires delving into the realm of Political Science – a
                  discipline that equips you to analyze power, governance, and
                  the complex interplay of forces that shape India’s political
                  landscape. This blog post explores the vast scope of learning
                  Political Science in the Indian context. We’ll delve into the
                  key areas of study, career opportunities, and valuable skills
                  you’ll acquire.
                </h1> -->
              </div>
              <div>
                <p class="text-left list" v-html="blogsDetail.content"></p>
              </div>
              <!-- left sticky icon -->
              <div class="flex items-center gap-6">
                <p class="text-[16px] text-[#000000]">Share</p>
                <img
                  src="@/assets/blogs_detail/logo.svg"
                  alt=""
                  srcset=""
                  class="w-[27px] h-[27px]"
                  @click.prevent="
                    shareOnTwitter(
                      blogsDetail.title,
                      `https://shardaonline.online/blogs/${blogsDetail.slug}`
                    )
                  "
                />
                <img
                  @click.prevent="
                    shareOnLinkedIn(
                      blogsDetail.title,
                      `https://shardaonline.online/blogs/${blogsDetail.slug}`
                    )
                  "
                  src="@/assets/blogs_detail/Icon awesome-linkedin.svg"
                  alt=""
                  srcset=""
                  class="w-[27px] h-[27px]"
                />
                <img
                  src="@/assets/blogs_detail/Icon awesome-link.svg"
                  alt=""
                  srcset=""
                  class="w-[27px] h-[27px] cursor-pointer"
                  @click.prevent="
                    copyLink(
                      `https://shardaonline.online/blogs/${blogsDetail.slug}`
                    )
                  "
                />
              </div>
              <!-- left sticky icon -->
            </div>
          </div>
        </div>
      </div>
      <!-- mobile banner-->
    </div>
    <!-- Related Article -->
    <div class="px-5 lg:px-8">
      <div class="max-w-7xl mx-auto">
        <div class="py-5 lg:ml-[8%] xl:ml-[6%]">
          <h2 class="text-[30px] text-left">
            <span class="text-[#18479E]">Related </span>
            <span class="text-[#EC027C]">Article</span>
          </h2>
          <!-- desktop -->
          <div class="py-5 hidden lg:block" v-if="this.articleBlogs.length > 0">
            <div class="articleCarousel flex justify-between">
              <div class="" v-for="blog in articleBlogs" :key="blog.id">
                <div
                  class="rounded-2xl flex flex-col gap-3 justify-center text-start shadow border bg-[#FFFFFF] p-5 lg:w-[260px] xl:w-[320px] h-[200px]"
                >
                  <h2 class="text-[#000000] text-[18px] font-normal">
                    {{ blog.title }}
                  </h2>
                  <p
                    class="text-[14px] text-[#454545] flex gap-3 items-center font-normal"
                  >
                    <span>{{ blog.category.title }}</span
                    >| <span>{{ formatDate(blog.publish_at) }}</span>
                  </p>
                  <a :href="`/blogs/${blog.slug}`">
                    <p
                      class="text-[14px] lg:text-[16px] font-medium text-[#EC027C]"
                    >
                      Read More
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- desktop -->

          <!-- mobile -->

          <div class="py-5 block lg:hidden" v-if="this.articleBlogs.length > 0">
            <div class="slick_related flex flex-col gap-5 w-full">
              <div class="w-full" v-for="blog in articleBlogs" :key="blog.id">
                <div
                  class="rounded-xl flex flex-col gap-2 justify-center text-start bg-white p-5 w-full h-[200px]"
                >
                  <h2 class="text-[#000000] text-[18px]">{{ blog.title }}</h2>
                  <p class="text-[14px] text-[#454545] flex gap-3 items-center">
                    <span>{{ blog.category.title }}</span
                    >| <span>{{ formatDate(blog.publish_at) }}</span>
                  </p>
                  <a :href="`/blogs/${blog.slug}`">
                    <p class="font-medium text-[16px] text-[#EC027C]">
                      Read More
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- mobile -->
        </div>
      </div>
    </div>

    <footerView />
    <stickyView />
  </div>
</template>

<script>
import headerView from "@/components/common/header.vue";
import footerView from "@/components/common/footer.vue";
import stickyView from "@/components/common/sticky.vue";
import $ from "jquery";
import "slick-carousel";
import axios from "axios";
export default {
  name: "BlogsDetail",
  components: {
    headerView,
    footerView,
    stickyView,
  },
  props: ["slug"],
  data() {
    return {
      relatedBlogs: [
        {
          id: 1,
          title:
            "Exploring the Benefits and Career Opportunities of an Online MBA in..",
          name: "Programs",
          dates: "January 31, 2024",
        },
        {
          id: 2,
          title:
            "The Complete Overview of Online Masters of Business Administration",
          name: "Programs",
          dates: "January 31, 2024",
        },
        {
          id: 3,
          title: "Why is an Online MBA Perfect for Working Professionals?",
          name: "Programs",
          dates: "January 31, 2024",
        },
        {
          id: 4,
          title:
            "Exploring the Benefits and Career Opportunities of an Online MBA in..",
          name: "Programs",
          dates: "January 31, 2024",
        },
      ],
      blogsDetail: [],
      popularData: [],
      articleBlogs: [],
      validatingCondition: false,
      linkedCopiedStatus: false,
    };
  },
  created() {
    this.fetchblogsdetail();
    this.fetchmostpopular();
  },
  methods: {
    shareOnTwitter(title, url) {
      const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        title
      )}&url=${encodeURIComponent(url)}`;
      window.open(twitterUrl, "_blank");
    },
    shareOnLinkedIn(title, url) {
      const linkedInUrl = `https://www.linkedin.com/shareArticle?mini=true&title=${encodeURIComponent(
        title
      )}&url=${encodeURIComponent(url)}`;
      window.open(linkedInUrl, "_blank");
    },
    copyLink(url) {
      navigator.clipboard
        .writeText(url)
        .then(() => {
          this.linkedCopiedStatus = true;
          setTimeout(() => {
            this.linkedCopiedStatus = false;
          }, 1000); // Change the delay as needed
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    },
    formatDate(dateString) {
      const options = { month: "short", year: "numeric" };
      const formattedDate = new Date(dateString).toLocaleDateString(
        "en-IN",
        options
      );
      // Extract day with appropriate suffix (e.g., 1st, 2nd, 3rd, 4th)
      const dayWithSuffix = this.getDayWithSuffix(
        new Date(dateString).getDate()
      );
      // Combine the formatted date and day with suffix
      return `${dayWithSuffix} ${formattedDate}`;
    },
    getDayWithSuffix(day) {
      if (day >= 11 && day <= 13) {
        return `${day}th`;
      }
      switch (day % 10) {
        case 1:
          return `${day}st`;
        case 2:
          return `${day}nd`;
        case 3:
          return `${day}rd`;
        default:
          return `${day}th`;
      }
    },
    async fetchblogsdetail() {
      await axios
        .get(`${process.env.VUE_APP_API}/blog/blog-list/?slug=${this.slug}`)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.blogsDetail = resp.data.data[0];
            this.validatingCondition = true;
            console.log(this.blogsDetail, "detail");
            this.fetchArticleBlogs(this.blogsDetail.category.title);
            console.log(this.blogsDetail);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async fetchmostpopular() {
      await axios
        .get(`${process.env.VUE_APP_API}/blog/blog-list/?trending=true`)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.popularData = resp.data.data;
            console.log(this.popularData, "popular");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async fetchArticleBlogs(title) {
      await axios
        .get(
          `${process.env.VUE_APP_API}/blog/blog-list/?category__title=${title}`
        )
        .then((response) => {
          this.articleBlogs = response.data.data;
          this.articleBlogs = this.articleBlogs.filter(
            (blog) => blog.id != this.blogsDetail.id
          );
          setTimeout(() => {
            $(`.articleCarousel`).slick({
              infinite: true,
              autoplay: true,
              prevArrow: null,
              nextArrow: null,
              slidesToShow: 3,
              slidesToScroll: 1,
              dots: true,
              dotsClass: "slick-dots",
              responsive: [
                {
                  breakpoint: 1280,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 640,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ],
            });
          }, 0);
        })
        .catch((error) => {
          console.error("Error fetching article blogs:", error);
        });
    },
  },
};
</script>

<style>
.slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
  padding: 1rem 0;
  list-style-type: none;
}

.slick-dots li {
  margin: 0 0.25rem;
}

.slick-dots button {
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  padding: 0;
  margin: 5px;
  border: none;
  border-radius: 100%;
  background-color: #18479e;
  text-indent: -9999px;
}

.slick-dots li.slick-active button {
  background-color: #ec027c;
  width: 0.5rem;
  height: 0.5rem;
}
.h1{
  font-size: 25px;
}
</style>
